import Logo from 'component/Logo';
import AccountMenu from 'component/AccountMenu';
import { Link, Toolbar, AppBar, GridContainer, GridItem, Divider } from 'element';

import MenuIcon from '@mui/icons-material/Menu';

import { useContext } from 'react';
import UserContext from 'context/UserContext';
import { Client } from 'types';


interface AppNavProps {
  setOpen?: (open: boolean) => void
  endInsuredClient?: Client
}


export default function AppNav({
  setOpen,
  endInsuredClient
}: AppNavProps) {

  const { user } = useContext(UserContext);
  
  return <AppBar position="static" sx={{justifyContent: "center"}}>
    <Toolbar sx={{justifyContent: "space-between", pl: ["54px"], pr: ["54px"]}}>
      {!endInsuredClient && <MenuIcon sx={{display: {lg: 'none'}, color: 'black', marginRight: "20px" }} onClick={()=>setOpen && setOpen(true)}/>}

      <Link href="/">
        <Logo endInsuredClient={endInsuredClient} />
      </Link>
      <GridContainer justifyContent="flex-end" alignItems="center">
        {!endInsuredClient && user && <>
          <GridItem>
            <AccountMenu />
          </GridItem>
        </>}        
      </GridContainer>
    </Toolbar>
  </AppBar>
}